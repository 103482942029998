import React from 'react';
import {
  Box, Text, VStack, Container, Heading, SimpleGrid, Flex, useColorModeValue,
  Icon, Button, Image, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody, ModalCloseButton, useDisclosure
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaQuoteLeft, FaFileAlt } from 'react-icons/fa';
import ReactCountryFlag from "react-country-flag";
import withLeadCollection from './withLeadCollection';

const MotionBox = motion(Box);

const FlagIcon = ({ country }) => {
  return country === 'USA' 
    ? <ReactCountryFlag countryCode="US" svg style={{ width: '16px', height: '16px', marginRight: '8px' }} />
    : <ReactCountryFlag countryCode="CA" svg style={{ width: '16px', height: '16px', marginRight: '8px' }} />;
};

const Testimonial = ({ quote, author, role, location, country, logo }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const quoteColor = useColorModeValue('blue.500', 'blue.300');
  const gradientColor = useColorModeValue(
    'linear(to-r, blue.50, purple.50)',
    'linear(to-r, blue.900, purple.900)'
  );

  const truncatedQuote = quote.length > 180 ? `${quote.substring(0, 180)}... ` : quote;

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.05 }}
    >
      <Box
        bg={bgColor}
        boxShadow="xl"
        p={8}
        rounded="xl"
        position="relative"
        zIndex={1}
        height="100%"
        transition="all 0.3s"
        _hover={{
          bgGradient: gradientColor,
          transform: 'translateY(-5px)',
        }}
      >
        <Icon
          as={FaQuoteLeft}
          color={quoteColor}
          fontSize="3xl"
          mb={4}
          opacity={0.3}
        />
        <Text fontSize="md" fontStyle="italic" color={textColor} mb={6}>
          "{truncatedQuote}"
          {quote.length > 180 && (
            <Button variant="link" color={quoteColor} onClick={onOpen} ml={2} fontSize="sm">
              Read More
            </Button>
          )}
        </Text>
        <Flex mt="auto" alignItems="flex-end" justifyContent="space-between">
          <Box>
            <Text fontWeight="bold" fontSize="lg">{author}</Text>
            <Text fontSize="sm" color={textColor}>{role}</Text>
            <Flex alignItems="center" mt={2}>
              <FlagIcon country={country} />
              <Text fontSize="sm" color={textColor}>{location}</Text>
            </Flex>
          </Box>
          <Image src={logo} alt={`${author}'s company logo`} maxHeight="30px" maxWidth="100px" objectFit="contain" />
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{author}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md" fontStyle="italic" mb={4}>"{quote}"</Text>
            <Flex alignItems="center" justifyContent="space-between" mt={4}>
              <Box>
                <Text fontWeight="bold">{author}</Text>
                <Text fontSize="sm" color={textColor}>{role}</Text>
                <Flex alignItems="center" mt={2}>
                  <FlagIcon country={country} />
                  <Text fontSize="sm" color={textColor}>{location}</Text>
                </Flex>
              </Box>
              <Image src={logo} alt={`${author}'s company logo`} maxHeight="30px" maxWidth="100px" objectFit="contain" />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MotionBox>
  );
};

const Testimonials = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const headingColor = useColorModeValue('gray.800', 'white');
  const ButtonWithLeadCollection = withLeadCollection(Button);

  const testimonials = [
    {
      quote: "I've been using Lexlink for just over a month and I'm already impressed with how incredibly useful it is. The platform's developers are exceptionally accommodating; whenever I articulate an idea for a new feature, they jump right into action, providing updates promptly and seeking my feedback to ensure it meets my needs. Their dedication to improving their product for attorneys is truly commendable. The search functionality on Lexlink has been a game-changer for our recent settlement. We were able to swiftly pinpoint specific documents from a vast archive by simply entering keywords, a task that previously consumed significant time. Additionally, the deposition summary feature has proven invaluable, organizing depositions into topic-based summaries that are easy to navigate. The file summary tool is also remarkable, swiftly identifying any inconsistencies or discrepancies in documents.",
      author: "Mackenzy Bean",
      role: "Associate Attorney",
      location: "Chicago, IL, USA",
      country: "USA",
      logo: "https://lexlinktransloadit.s3.amazonaws.com/sharemedia/Ruberry-Logo.png"
    },
    {
      quote: "Lexlink AI has dramatically improved our case analysis process. Its proficiency in detecting inconsistencies and contradictions in legal documents has become indispensable in our case preparation. The time saved is invaluable, and the insights generated have given us a significant edge in court.",
      author: "Brian Davalos",
      role: "Managing Attorney",
      location: "Stockton, CA, USA",
      country: "USA",
      logo: "https://thedavaloslawfirm.com/wp-content/uploads/2020/11/Davalos-Law-Firm-Stockton-Logo-500px.png"
    },
    {
      quote: "Since using Lexlink AI, we have revolutionized our approach to personal injury litigation. The automated document review and summarization features have dramatically reduced the time we spend sifting through vast amounts of medical records and accident reports. What used to take days now takes mere hours. The inconsistency reports are a game-changer when it comes to assessing credibility. They've helped us identify discrepancies in statements and timelines that we might have otherwise missed, giving us a significant advantage in negotiations and court proceedings. The platform's ability to quickly highlight key information has improved our case preparation efficiency by at least 40%. It's not just about saving time; it's about enhancing the quality of our work. With Lexlink AI, we're able to build stronger cases and serve our clients better. The developers are constantly improving the platform based on user feedback, which shows their commitment to the legal community. For any firm handling personal injury cases, Lexlink AI isn't just helpful – it's becoming essential.",
      author: "Kay Grewal",
      role: "Managing Partner",
      location: "Toronto, ON, Canada",
      country: "Canada",
      logo: "https://greylaw.ca/wp-content/uploads/sites/232/2023/07/Logo130x.jpg"
    },
  ];

  return (
    <Box id="testimonials" bg={bgColor} position="relative" overflow="hidden" py={20}>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="6px"
        bgGradient="linear(to-r, blue.400, purple.500)"
      />
      <Container maxW="1200px">
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <VStack spacing={12}>
            <Box textAlign="center">
              <Heading as="h2" size="2xl" mb={4} color={headingColor}>
                Trusted by Legal Professionals Worldwide
              </Heading>
              <Text fontSize="xl" maxW="800px" mx="auto" color={useColorModeValue('gray.600', 'gray.400')}>
                See how Lexlink AI is transforming legal practices and empowering attorneys to win more cases.
              </Text>
            </Box>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} width="100%">
              <AnimatePresence>
                {testimonials.map((testimonial, index) => (
                  <Testimonial key={index} {...testimonial} />
                ))}
              </AnimatePresence>
            </SimpleGrid>
            <ButtonWithLeadCollection
              size="lg"
              colorScheme="blue"
              rightIcon={<FaFileAlt />}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
              transition="all 0.3s"
            >
              Read Customer Case Studies
            </ButtonWithLeadCollection>
          </VStack>
        </MotionBox>
      </Container>
    </Box>
  );
};

export default Testimonials;
