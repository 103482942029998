import React, { useState } from 'react';
import {
  Box, VStack, HStack, Text, Icon, Container, Heading, useColorModeValue,
  Flex, Button, SimpleGrid, Badge, Collapse, Circle, Grid, GridItem
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FaUpload, FaRobot, FaChartLine, FaArrowRight, FaClock, FaSearch, FaBalanceScale, FaExclamationTriangle, FaLink, FaLightbulb
} from 'react-icons/fa';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import withLeadCollection from './withLeadCollection';

const MotionBox = motion(Box);
const DemoButton = withLeadCollection(Button);

const StepIndicator = ({ isActive, isHovered, number }) => {
  const bgColor = isActive || isHovered ? 'blue.500' : 'gray.200';
  const color = isActive || isHovered ? 'white' : 'gray.500';
  
  return (
    <Circle size="40px" bg={bgColor} color={color} fontWeight="bold">
      {number}
    </Circle>
  );
};

const Step = ({ step, isActive, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const gradientColor = useColorModeValue(
    'linear(to-r, blue.50, purple.50)',
    'linear(to-r, blue.900, purple.900)'
  );

  return (
    <Box
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor="pointer"
      bg={isHovered || isActive ? gradientColor : bgColor}
      p={6}
      borderRadius="lg"
      boxShadow="md"
      transition="all 0.3s"
      _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="4px"
        bgGradient="linear(to-r, blue.400, purple.500)"
        opacity={isHovered || isActive ? 1 : 0}
        transition="opacity 0.3s"
      />
      <HStack spacing={4}>
        <StepIndicator isActive={isActive} isHovered={isHovered} number={step.id} />
        <VStack align="start" spacing={2}>
          <Heading size="md" color={isActive ? 'blue.500' : textColor}>
            {step.title}
          </Heading>
          <Text color={textColor}>{step.description}</Text>
        </VStack>
      </HStack>
    </Box>
  );
};

const InteractiveVisualization = ({ step }) => {
  const lineStrokeColor = useColorModeValue('#3182CE', '#63B3ED');
  const barFillColor = useColorModeValue('#3182CE', '#63B3ED');
  const pieFillColors = ['#0088FE', '#00C49F', '#FFBB28'];

  if (step.id === 1) {
    const data = [
      { name: 'Day 1', value: 10 },
      { name: 'Day 2', value: 30 },
      { name: 'Day 3', value: 45 },
      { name: 'Day 4', value: 80 },
      { name: 'Day 5', value: 100 },
    ];

    return (
      <Box height="300px" width="100%">
        <ResponsiveContainer>
          <LineChart data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke={lineStrokeColor} strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  } else if (step.id === 2) {
    const data = [
      { name: 'Inconsistencies', value: 15 },
      { name: 'Key Statements', value: 45 },
      { name: 'Documents', value: 30 },
    ];

    return (
      <Box height="300px" width="100%">
        <ResponsiveContainer>
          <BarChart data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill={barFillColor} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  } else {
    const data = [
      { name: 'Relevant Cases', value: 35 },
      { name: 'Key Arguments', value: 25 },
      { name: 'Supporting Evidence', value: 40 },
    ];

    return (
      <Box height="300px" width="100%">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieFillColors[index % 3]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    );
  }
};

const DetailView = ({ step }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  return (
    <Box bg={bgColor} p={8} borderRadius="lg" boxShadow="md" mt={4}>
      <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8}>
        <GridItem>
          <VStack align="start" spacing={6}>
            <Heading size="lg" color="blue.500">Key Benefits</Heading>
            {step.benefits.map((benefit, index) => (
              <HStack key={index} spacing={4} align="start">
                <Icon as={benefit.icon} color="green.500" boxSize={6} mt={1} />
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold" color={textColor}>{benefit.title}</Text>
                  <Text fontSize="md" color={textColor}>{benefit.description}</Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </GridItem>
        <GridItem>
          <InteractiveVisualization step={step} />
        </GridItem>
      </Grid>
      <DemoButton
        mt={8}
        colorScheme="blue"
        rightIcon={<FaArrowRight />}
        size="lg"
        width="full"
        _hover={{
          transform: 'translateY(-2px)',
          boxShadow: 'lg',
          bg: 'blue.600',
        }}
        transition="all 0.3s"
      >
        <Icon as={step.actionIcon} mr={2} />
        {step.actionText}
      </DemoButton>
    </Box>
  );
};

const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(null);
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  const steps = [
    {
      id: 1,
      icon: FaUpload,
      title: "Effortless Case Setup",
      description: "Upload files and let AI handle the rest.",
      benefits: [
        { icon: FaClock, title: "Time-Saving", description: "Reduce manual work by up to 80%" },
        { icon: FaSearch, title: "Smart Categorization", description: "AI-powered document sorting and tagging" },
        { icon: FaRobot, title: "Instant Analysis", description: "Get initial insights immediately after upload" }
      ],
      actionIcon: FaUpload,
      actionText: "Try Case Upload",
    },
    {
      id: 2,
      icon: FaRobot,
      title: "Instant AI-Powered Insights",
      description: "Get critical insights within minutes.",
      benefits: [
        { icon: FaExclamationTriangle, title: "Inconsistency Detection", description: "Automatically flag conflicting statements" },
        { icon: FaSearch, title: "Key Information Extraction", description: "Identify crucial facts and statements" },
        { icon: FaLink, title: "Document Correlation", description: "Visualize connections across all case files" }
      ],
      actionIcon: FaSearch,
      actionText: "Explore AI Insights",
    },
    {
      id: 3,
      icon: FaChartLine,
      title: "Strategic Case Building",
      description: "Develop winning strategies with AI assistance.",
      benefits: [
        { icon: FaBalanceScale, title: "Strength Assessment", description: "Evaluate the robustness of your arguments" },
        { icon: FaSearch, title: "Precedent Discovery", description: "Find relevant cases to support your position" },
        { icon: FaLightbulb, title: "Strategy Generation", description: "Get AI-powered suggestions for case strategy" }
      ],
      actionIcon: FaChartLine,
      actionText: "Build Your Strategy",
    }
  ];

  return (
    <Box id="how-it-works" bg={bgColor} py={20} position="relative" overflow="hidden">
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="6px"
        bgGradient="linear(to-r, blue.400, purple.500)"
      />
      <Container maxW="1200px">
        <VStack spacing={12}>
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Heading as="h2" size="2xl" textAlign="center" mb={4} color={textColor}>
              How Lexlink AI Works
            </Heading>
            <Text textAlign="center" fontSize="xl" maxW="800px" mx="auto" color={textColor}>
              Revolutionize your legal practice with AI-driven insights and automation.
            </Text>
          </MotionBox>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} width="100%">
            {steps.map((step) => (
              <Step
                key={step.id}
                step={step}
                isActive={activeStep === step.id}
                onClick={() => setActiveStep(activeStep === step.id ? null : step.id)}
              />
            ))}
          </SimpleGrid>
          <AnimatePresence>
            {activeStep && (
              <MotionBox
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                width="100%"
              >
                <DetailView step={steps.find(s => s.id === activeStep)} />
              </MotionBox>
            )}
          </AnimatePresence>
          <Box textAlign="center">
            <DemoButton
              size="lg"
              colorScheme="blue"
              rightIcon={<FaArrowRight />}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
                bg: 'blue.600',
              }}
              transition="all 0.3s"
            >
              <Icon as={FaRobot} mr={2} />
              Request AI Demo
            </DemoButton>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default HowItWorks;
