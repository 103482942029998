import React from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  Image,
  IconButton,
  Link,
  Button,
  HStack
} from '@chakra-ui/react';
import { FaTwitter, FaLinkedin, FaInstagram, FaEnvelope, FaGavel } from 'react-icons/fa';
import withLeadCollection from './withLeadCollection';
import { motion } from 'framer-motion';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

const ContactButton = withLeadCollection(Button);
const NewsletterButton = withLeadCollection(Button);

const AnimatedText = ({ text }) => {
  return (
    <motion.span
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
      style={{ fontWeight: 'bold', fontSize: '1.2rem', letterSpacing: '0.05em' }}
    >
      {text}
    </motion.span>
  );
};

const DarkModeLogo = () => {
  return (
    <HStack spacing={2} alignItems="center">
      <Box as={FaGavel} size="24px" transform="scale(-1, 1)" color="white" />
      <AnimatedText text="LEXLINK AI" />
    </HStack>
  );
};

const Footer = ({ onNavClick }) => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const logoColorMode = useColorModeValue(
    <Image 
      src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F721ccf32dbe195999fddaed6054605f3.cdn.bubble.io%2Ff1706025416351x901717552793873700%2Flex%2520perjury?w=192&h=54&auto=compress&dpr=2&fit=max" 
      alt="Lexlink AI" 
      height="32px"
    />,
    <DarkModeLogo />
  );

  return (
    <Box
      bg={bgColor}
      color={textColor}
      position="relative"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="6px"
        bgGradient="linear(to-r, blue.400, purple.500)"
      />
      <Container as={Stack} maxW={'1200px'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <Link href="#home" onClick={(e) => onNavClick('home', e)}>Home</Link>
            <Link href="#blog" onClick={(e) => onNavClick('blog', e)}>Blog</Link>
            <Link href="#careers" onClick={(e) => onNavClick('careers', e)}>Careers</Link>
            <ContactButton variant="link" color={textColor}>Contact Us</ContactButton>
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader>Legal</ListHeader>
            <Link href="#terms" onClick={(e) => onNavClick('terms', e)}>Terms of Use</Link>
            <Link href="#privacy" onClick={(e) => onNavClick('privacy', e)}>Privacy Policy</Link>
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader>Stay Connected</ListHeader>
            <Flex direction="row" spacing={6}>
              <IconButton as="a" href="https://twitter.com/perjuryai" target="_blank" aria-label="Twitter" icon={<FaTwitter />} size="md" color={'blue.400'} variant="ghost" />
              <IconButton as="a" href="https://www.linkedin.com/company/lexlinkai/" target="_blank" aria-label="LinkedIn" icon={<FaLinkedin />} size="md" color={'blue.400'} variant="ghost" />
              <IconButton as="a" href="https://instagram.com/lexlink.ai" target="_blank" aria-label="Instagram" icon={<FaInstagram />} size="md" color={'blue.400'} variant="ghost" />
              <IconButton as="a" href="mailto:hello@lexlink.ai" aria-label="Email" icon={<FaEnvelope />} size="md" color={'blue.400'} variant="ghost" />
            </Flex>
            <NewsletterButton colorScheme="blue" mt={4} leftIcon={<FaEnvelope />}>
              Subscribe to our newsletter
            </NewsletterButton>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box py={10}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Box onClick={(e) => onNavClick('home', e)} cursor="pointer">
            {logoColorMode}
          </Box>
        </Flex>
        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          © 2024 Lexlink AI. All rights reserved
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
