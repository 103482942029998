import React from 'react';
import {
  Box, Flex, Text, IconButton, Button, Stack, Collapse,
  Link, Popover, PopoverTrigger, PopoverContent, useColorModeValue,
  useDisclosure, Container, Image, HStack, useColorMode
} from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import { FaSearch, FaFileAlt, FaExclamationTriangle, FaChartLine, FaFileSignature, FaTag, FaPencilAlt, FaBlog, FaBookOpen, FaGavel } from 'react-icons/fa';
import { motion } from 'framer-motion';
import withLeadCollection from './withLeadCollection';

const DemoButton = withLeadCollection(Button);
const CaseStudiesLink = withLeadCollection(Link);

const AnimatedText = ({ text }) => {
  return (
    <motion.span
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
      style={{ fontWeight: 'bold', fontSize: '1.2rem', letterSpacing: '0.05em' }}
    >
      {text}
    </motion.span>
  );
};

const DarkModeLogo = () => {
  return (
    <HStack spacing={2} alignItems="center">
      <Box as={FaGavel} size="24px" transform="scale(-1, 1)" color="white" />
      <AnimatedText text="LEXLINK AI" />
    </HStack>
  );
};

export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box position="fixed" top={0} left={0} right={0} zIndex={1000}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Container maxW="1200px">
          <Flex align="center" justify="space-between">
            <Link href="#home">
              {colorMode === 'light' ? (
                <Image 
                  src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F721ccf32dbe195999fddaed6054605f3.cdn.bubble.io%2Ff1706025416351x901717552793873700%2Flex%2520perjury?w=192&h=54&auto=compress&dpr=2&fit=max" 
                  alt="Lexlink AI" 
                  h="40px" 
                />
              ) : (
                <DarkModeLogo />
              )}
            </Link>

            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={'flex-end'}
              direction={'row'}
              spacing={6}
              align="center"
            >
              <IconButton
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                variant="ghost"
                aria-label="Toggle color mode"
              />
              <Button
                as={'a'}
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
                href={'https://app.lexlink.ai/login'}
              >
                Login
              </Button>
              <DemoButton
                display={{ base: 'none', md: 'inline-flex' }}
                fontSize={'sm'}
                fontWeight={600}
                color={'white'}
                bg={'blue.400'}
                _hover={{
                  bg: 'blue.300',
                }}
              >
                Request Demo
              </DemoButton>
            </Stack>

            <IconButton
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
              display={{ base: 'flex', md: 'none' }}
            />
          </Flex>
        </Container>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} position="relative">
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
                {navItem.children && (
                  <ChevronDownIcon ml={1} w={4} h={4} />
                )}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
                zIndex={5}
                mt="4px"
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, icon: Icon }) => {
  const hoverBg = useColorModeValue('blue.50', 'gray.900');
  
  const LinkComponent = label === 'Case Studies' ? CaseStudiesLink : Link;
  
  return (
    <LinkComponent
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: hoverBg }}
    >
      <HStack align={'center'}>
        <Box color={'blue.400'} width="20px">
          <Icon />
        </Box>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'blue.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
      </HStack>
    </LinkComponent>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  const handleClick = (e) => {
    if (children) {
      onToggle();
    } else if (href) {
      e.preventDefault();
      window.location.hash = href;
    }
  };

  return (
    <Stack spacing={4} onClick={handleClick}>
      <Flex
        py={2}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
        {children && (
          <ChevronDownIcon
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => {
              const LinkComponent = child.label === 'Case Studies' ? CaseStudiesLink : Link;
              return (
                <LinkComponent key={child.label} py={2} href={child.href}>
                  {child.label}
                </LinkComponent>
              );
            })}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Features',
    children: [
      {
        label: 'Intelligent Statement Search',
        subLabel: 'Rapidly locate critical information',
        href: '#feature-search',
        icon: FaSearch,
      },
      {
        label: 'Comprehensive Case Summary',
        subLabel: 'AI-powered case analysis',
        href: '#feature-summary',
        icon: FaFileAlt,
      },
      {
        label: 'Automated Inconsistency Detection',
        subLabel: 'Catch discrepancies automatically',
        href: '#feature-inconsistency',
        icon: FaExclamationTriangle,
      },
      {
        label: 'Dynamic Timeline Creation',
        subLabel: 'Visualize case events',
        href: '#feature-timeline',
        icon: FaChartLine,
      },
      {
        label: 'Deposition Summaries',
        subLabel: 'Quick generation and review',
        href: '#feature-deposition',
        icon: FaFileSignature,
      },
      {
        label: 'Automated File Briefs',
        subLabel: 'Understand document contents quickly',
        href: '#feature-file-briefs',
        icon: FaFileAlt,
      },
      {
        label: 'Smart File Tagging',
        subLabel: 'Efficient document organization',
        href: '#feature-tagging',
        icon: FaTag,
      },
      {
        label: 'DiscoDraft',
        subLabel: 'Streamline discovery requests',
        href: '#feature-discodraft',
        icon: FaPencilAlt,
      },
    ],
  },
  {
    label: 'How It Works',
    href: '#how-it-works',
  },
  {
    label: 'Testimonials',
    href: '#testimonials',
  },
  {
    label: 'FAQ',
    href: '#faq',
  },
  {
    label: 'Careers',
    href: '#careers',
  },
  {
    label: 'Resources',
    children: [
      {
        label: 'Blog',
        subLabel: 'Latest insights and updates',
        href: '#blog',
        icon: FaBlog,
      },
      {
        label: 'Case Studies',
        subLabel: 'See how others succeeded',
        href: '#case-studies',
        icon: FaBookOpen,
      },
    ],
  },
];