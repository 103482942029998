import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import ReactGA from 'react-ga4';
import theme from './theme';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import Testimonials from './components/Testimonials';
import CallToAction from './components/CallToAction';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Blog from './components/Blog';
import Careers from './components/Careers';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import { ColorModeProvider } from './ColorModeContext';

// Initialize Google Analytics
ReactGA.initialize('G-LCV8Y3ETM5');

function App() {
  const [activePage, setActivePage] = useState('home');

  useEffect(() => {
    const handleHashChange = () => {
      const newPage = window.location.hash.slice(1) || 'home';
      
      if (['feature-search', 'feature-summary', 'feature-inconsistency', 'feature-timeline', 'feature-deposition', 'feature-file-briefs', 'feature-tagging', 'feature-discodraft', 'how-it-works', 'testimonials', 'faq', 'call-to-action'].includes(newPage)) {
        if (activePage !== 'home') {
          setActivePage('home');
          setTimeout(() => {
            const element = document.getElementById(newPage);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }, 0);
        } else {
          const element = document.getElementById(newPage);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      } else {
        setActivePage(newPage);
        window.scrollTo(0, 0);
      }

      // Send pageview to Google Analytics
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search + window.location.hash });
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // handle the initial load
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [activePage]);

  const handleNavClick = (page, event) => {
    if (event) {
      event.preventDefault();
    }
    setActivePage(page);
    window.location.hash = `#${page}`;

    // Send event to Google Analytics
    ReactGA.event({
      category: 'Navigation',
      action: 'Click',
      label: page
    });
  };

  const renderActivePage = () => {
    switch (activePage) {
      case 'home':
        return (
          <>
            <Box id="home">
              <Hero />
            </Box>
            <Features />
            <Box id="how-it-works">
              <HowItWorks />
            </Box>
            <Box id="testimonials">
              <Testimonials />
            </Box>
            <Box id="faq">
              <FAQ />
            </Box>
            <Box id="call-to-action">
              <CallToAction />
            </Box>
          </>
        );
      case 'blog':
        return <Blog />;
      case 'careers':
        return <Careers />;
      case 'terms':
        return <TermsOfUse />;
      case 'privacy':
        return <PrivacyPolicy />;
      default:
        return (
          <>
            <Box id="home">
              <Hero />
            </Box>
            <Features />
            <Box id="how-it-works">
              <HowItWorks />
            </Box>
            <Box id="testimonials">
              <Testimonials />
            </Box>
            <Box id="faq">
              <FAQ />
            </Box>
            <Box id="call-to-action">
              <CallToAction />
            </Box>
          </>
        );
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <Box>
          <Header onNavClick={handleNavClick} />
          {renderActivePage()}
          <Footer onNavClick={handleNavClick} />
        </Box>
      </ColorModeProvider>
    </ChakraProvider>
  );
}

export default App;