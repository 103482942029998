import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  VStack,
  Text,
  useColorModeValue,
  Icon,
  InputGroup,
  InputLeftElement,
  Textarea,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaUser, FaEnvelope, FaBriefcase, FaBuilding, FaComments, FaPhone } from 'react-icons/fa';

const LeadCollectionModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    jobTitle: '',
    company: '',
    comment: '',
    subscribe: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const inputBg = useColorModeValue('gray.100', 'gray.700');
  const placeholderColor = useColorModeValue('gray.500', 'gray.400');

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await fetch('https://7d90ldj0mj.execute-api.us-east-1.amazonaws.com/dev/mailchimp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const data = await response.json();
        toast({
          title: "Success!",
          description: "Thank you for your interest. We'll be in touch soon.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: "An error occurred.",
        description: "Unable to submit your request. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        as={motion.div}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
        bg={bgColor}
        borderRadius="xl"
        boxShadow="2xl"
      >
        <ModalHeader
          bgGradient="linear(to-r, blue.400, purple.500)"
          color="white"
          borderTopRadius="xl"
          py={6}
        >
          Discover How Lexlink AI Can Transform Your Practice
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody py={8}>
          <VStack spacing={6} as="form" onSubmit={handleSubmit}>
            <Text fontSize="lg" textAlign="center" color={textColor}>
              Join top law firms already leveraging AI to win more cases. Request your personalized demo today.
            </Text>
            <FormControl isRequired>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaUser} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="firstName"
                  placeholder="First Name"
                  bg={inputBg}
                  border="none"
                  _placeholder={{ color: placeholderColor }}
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaUser} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="lastName"
                  placeholder="Last Name"
                  bg={inputBg}
                  border="none"
                  _placeholder={{ color: placeholderColor }}
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaEnvelope} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  bg={inputBg}
                  border="none"
                  _placeholder={{ color: placeholderColor }}
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaPhone} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="phone"
                  placeholder="Phone Number"
                  bg={inputBg}
                  border="none"
                  _placeholder={{ color: placeholderColor }}
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaBriefcase} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="jobTitle"
                  placeholder="Job Title"
                  bg={inputBg}
                  border="none"
                  _placeholder={{ color: placeholderColor }}
                  value={formData.jobTitle}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaBuilding} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="company"
                  placeholder="Law Firm / Company"
                  bg={inputBg}
                  border="none"
                  _placeholder={{ color: placeholderColor }}
                  value={formData.company}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaComments} color="gray.300" />
                </InputLeftElement>
                <Textarea
                  name="comment"
                  placeholder="How can Lexlink AI help your practice?"
                  bg={inputBg}
                  border="none"
                  _placeholder={{ color: placeholderColor }}
                  value={formData.comment}
                  onChange={handleInputChange}
                  pl={10}
                  minH="100px"
                />
              </InputGroup>
            </FormControl>
            <Checkbox
              name="subscribe"
              isChecked={formData.subscribe}
              onChange={handleInputChange}
              colorScheme="blue"
            >
              Subscribe to our newsletter for legal tech insights
            </Checkbox>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            colorScheme="blue"
            mr={3}
            isLoading={isLoading}
            loadingText="Submitting"
            onClick={handleSubmit}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            transition="all 0.3s"
          >
            Request Your Demo
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeadCollectionModal;
