import React, { useState, useEffect } from 'react';
import {
  Box, Container, SimpleGrid, Flex, Heading, Text, VStack, HStack, Icon, Button,
  useColorModeValue, Input, InputGroup, InputLeftElement, Tag,
  Tabs, TabList, Tab, TabPanels, TabPanel, Badge,
  UnorderedList, OrderedList, ListItem, TagLabel, TagCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaSearch, FaArrowRight, FaFileAlt, FaBalanceScale, FaChartLine, FaCalendarAlt, FaFileSignature, FaTag, FaPencilAlt, FaQuoteLeft, FaFile, FaExclamationCircle } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Network } from 'vis-network/standalone/esm/vis-network';
import withLeadCollection from './withLeadCollection';

const MotionBox = motion(Box);
const DemoButton = withLeadCollection(Button);

const FeatureSection = ({ title, description, icon, children, isEven, onOpen, id }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const accentColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <Flex
      id={id} // Set the id attribute
      direction={{ base: 'column', lg: isEven ? 'row-reverse' : 'row' }}
      bg={bgColor}
      p={{ base: 8, md: 12 }}
      alignItems="center"
      justifyContent="space-between"
      borderRadius="2xl"
      boxShadow="xl"
      overflow="hidden"
      position="relative"
      transition="all 0.3s"
      _hover={{
        transform: 'translateY(-5px)',
        boxShadow: '2xl',
      }}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="6px"
        bgGradient={`linear(to-r, ${accentColor}, purple.500)`}
      />
      <VStack
        width={{ base: '100%', lg: '45%' }}
        mb={{ base: 8, lg: 0 }}
        alignItems="flex-start"
        spacing={6}
        zIndex={1}
      >
        <Flex
          alignItems="center"
          bg={useColorModeValue('gray.100', 'gray.700')}
          p={4}
          borderRadius="full"
          boxShadow="md"
          maxWidth="100%"
          transition="all 0.3s"
          _hover={{ transform: 'scale(1.05)', boxShadow: 'lg' }}
        >
          <MotionBox
            animate={{ rotate: [0, 10, -10, 0] }}
            transition={{ repeat: Infinity, duration: 2 }}
          >
            <Icon as={icon} boxSize={{ base: 6, md: 7 }} color={accentColor} mr={4} />
          </MotionBox>
          <Heading
            as="h3"
            fontSize={{ base: "md", md: "xl" }} // Adjusted font size for better mobile display
            color={textColor}
            noOfLines={{ base: 3, md: 1 }} // Ensure no truncation on mobile
          >
            {title}
          </Heading>
        </Flex>
        <Text fontSize={{ base: "md", md: "lg" }} color={textColor} fontWeight="medium" lineHeight="tall">
          {description}
        </Text>
        <DemoButton
          as={motion.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          colorScheme="blue"
          size={{ base: "md", md: "lg" }}
          rightIcon={<FaArrowRight />}
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
          transition="all 0.3s"
          onClick={onOpen}
        >
          Learn More
        </DemoButton>
      </VStack>
      <Box
        width={{ base: '100%', lg: '50%' }}
        p={6}
        borderRadius="lg"
        zIndex={1}
        display={{ base: 'none', md: 'block' }}
      >
        {children}
      </Box>
    </Flex>
  );
};

const SearchPreview = () => {
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const bgBox = useColorModeValue('white', 'gray.700');
    const [tabIndex, setTabIndex] = useState(1); // Set default tab to "Statements"
  
    return (
      <Box>
        <VStack align="stretch" spacing={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color="gray.300" />
            </InputLeftElement>
            <Input placeholder="Search across all documents..." />
          </InputGroup>
          <Tabs index={tabIndex} onChange={index => setTabIndex(index)}>
            <TabList>
              <Tab>Speakers</Tab>
              <Tab>Statements</Tab>
              <Tab>Files</Tab>
              <Tab>Topics</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack align="stretch" spacing={4}>
                  {['John Doe', 'Jane Smith', 'Mark Johnson'].map((speaker, index) => (
                    <Box key={index} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor="gray.200" bg={bgBox}>
                      <HStack>
                        <Icon as={FaFileAlt} color="blue.500" />
                        <Text fontWeight="bold" color={textColor}>{speaker}</Text>
                      </HStack>
                      <HStack mt={2} spacing={2}>
                        <Badge colorScheme="green">5 Documents</Badge>
                        <Badge colorScheme="purple">3 Topics</Badge>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack align="stretch" spacing={4}>
                  {[
                    '"I clearly remember discussing the contract terms on July 15th, 2023."',
                    '"The meeting was scheduled for 2 PM, not 3 PM as previously stated."',
                    '"We never received the amended agreement via email."'
                  ].map((statement, index) => (
                    <Box key={index} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor="gray.200" bg={bgBox}>
                      <HStack>
                        <Icon as={FaQuoteLeft} color="blue.500" />
                        <Text color={textColor}>{statement}</Text>
                      </HStack>
                      <HStack mt={2} spacing={2}>
                        <Badge colorScheme="blue">John Doe</Badge>
                        <Badge colorScheme="green">Deposition</Badge>
                        <Badge colorScheme="purple">Contract Terms</Badge>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack align="stretch" spacing={4}>
                  {['Deposition_JohnDoe.pdf', 'Contract_v2.docx', 'Email_Thread_20230716.eml'].map((file, index) => (
                    <Box key={index} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor="gray.200" bg={bgBox}>
                      <HStack>
                        <Icon as={FaFile} color="blue.500" />
                        <Text color={textColor}>{file}</Text>
                      </HStack>
                      <HStack mt={2} spacing={2}>
                        <Badge colorScheme="orange">PDF</Badge>
                        <Badge colorScheme="green">4 Speakers</Badge>
                        <Badge colorScheme="purple">2 Topics</Badge>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack align="stretch" spacing={4}>
                  {['Contract Dispute', 'Witness Testimony', 'Document Authenticity'].map((topic, index) => (
                    <Box key={index} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor="gray.200" bg={bgBox}>
                      <HStack>
                        <Icon as={FaTag} color="blue.500" />
                        <Text color={textColor}>{topic}</Text>
                      </HStack>
                      <HStack mt={2} spacing={2}>
                        <Badge colorScheme="blue">10 Mentions</Badge>
                        <Badge colorScheme="green">3 Documents</Badge>
                        <Badge colorScheme="purple">2 Speakers</Badge>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Box>
    );
  };
  
  

const CaseAnalysisPreview = () => {
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const nodeTextColor = useColorModeValue('#000000', '#FFFFFF');

  useEffect(() => {
    const container = document.getElementById('mynetwork');
    const data = {
      nodes: [
        { id: 1, label: 'Case', group: 'case' },
        { id: 2, label: 'Witness A', group: 'person' },
        { id: 3, label: 'Document 1', group: 'document' },
        { id: 4, label: 'Topic: Contract', group: 'topic' },
        { id: 5, label: 'Inconsistency', group: 'inconsistency' },
      ],
      edges: [
        { from: 1, to: 2 },
        { from: 1, to: 3 },
        { from: 2, to: 3 },
        { from: 3, to: 4 },
        { from: 3, to: 5 },
      ]
    };
    const options = {
      nodes: {
        shape: 'dot',
        size: 30,
        font: {
          size: 14,
          color: nodeTextColor, // Use the color value here
        },
        borderWidth: 2
      },
      edges: {
        width: 2,
        color: { color: '#808080', highlight: '#00BFFF' }
      },
      groups: {
        case: {
          color: { background: '#4169E1', border: '#0000CD' }
        },
        person: {
          color: { background: '#32CD32', border: '#228B22' }
        },
        document: {
          color: { background: '#FFD700', border: '#DAA520' }
        },
        topic: {
          color: { background: '#9370DB', border: '#4B0082' }
        },
        inconsistency: {
          color: { background: '#DC143C', border: '#8B0000' }
        }
      },
      interaction: {
        zoomView: false,  // Disable zooming
      },
    };
    new Network(container, data, options);
  }, [nodeTextColor]); // Add nodeTextColor to dependencies

  return (
    <Box>
      <Heading size="md" mb={4} color={textColor}>Lexlink AI Analysis</Heading>
      <Box id="mynetwork" height="300px" border="1px solid" borderColor="gray.200" borderRadius="md" />
      <HStack mt={4} spacing={4} justifyContent="center">
        <Badge colorScheme="blue">Case</Badge>
        <Badge colorScheme="green">Person</Badge>
        <Badge colorScheme="yellow">Document</Badge>
        <Badge colorScheme="purple">Topic</Badge>
        <Badge colorScheme="red">Inconsistency</Badge>
      </HStack>
    </Box>
  );
};

const InconsistencyPreview = () => {
  const bgColorHigh = useColorModeValue('red.50', 'red.900');
  const bgColorMedium = useColorModeValue('yellow.50', 'yellow.900');
  const textColorHigh = useColorModeValue('red.500', 'red.200');
  const textColorMedium = useColorModeValue('yellow.500', 'yellow.200');

  return (
    <VStack align="stretch" spacing={4}>
      <Box p={4} borderRadius="md" boxShadow="md" border="1px" borderColor={textColorHigh} bg={bgColorHigh}>
        <HStack>
          <Icon as={FaExclamationCircle} color={textColorHigh} />
          <Text fontWeight="bold" color={textColorHigh}>Inconsistency Detected</Text>
        </HStack>
        <Text mt={2} color={textColorHigh}>
          "The defendant claimed they were out of town on July 15th, but email records show they sent a message from the office on that date."
        </Text>
        <HStack mt={2} spacing={2}>
          <Badge colorScheme="red">High Priority</Badge>
          <Badge colorScheme="blue">2 Related Documents</Badge>
          <Badge colorScheme="purple">Alibi</Badge>
        </HStack>
      </Box>
      <Box p={4} borderRadius="md" boxShadow="md" border="1px" borderColor={textColorMedium} bg={bgColorMedium}>
        <HStack>
          <Icon as={FaExclamationCircle} color={textColorMedium} />
          <Text fontWeight="bold" color={textColorMedium}>Potential Discrepancy</Text>
        </HStack>
        <Text mt={2} color={textColorMedium}>
          "Witness A's testimony about the meeting time conflicts with the schedule found in the recovered documents."
        </Text>
        <HStack mt={2} spacing={2}>
          <Badge colorScheme="yellow">Medium Priority</Badge>
          <Badge colorScheme="blue">3 Related Documents</Badge>
          <Badge colorScheme="purple">Meeting Schedule</Badge>
        </HStack>
      </Box>
    </VStack>
  );
};

const TimelinePreview = () => {
  const [viewMode, setViewMode] = useState('chart'); // Default to chart view
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const borderColor = useColorModeValue('blue.200', 'blue.700');

  const listData = [
    { date: '2023-01-15', event: 'Contract Signed' },
    { date: '2023-03-01', event: 'Dispute Arises' },
    { date: '2023-04-10', event: 'Legal Action Initiated' },
    { date: '2023-05-20', event: 'Mediation Attempt' },
    { date: '2023-06-30', event: 'Court Hearing' },
  ];

  const chartData = {
    labels: listData.map(item => item.date),
    datasets: [{
      label: 'Case Events',
      data: listData.map((_, index) => index + 1),
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderWidth: 2,
      pointRadius: 6,
      pointHoverRadius: 8,
      fill: true,
    }]
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: listData.length,
        ticks: {
          stepSize: 1,
          callback: function(value, index, values) {
            return listData[index]?.event;
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.label}: ${listData[context.dataIndex].event}`;
          }
        }
      }
    }
  };

  return (
    <Box width="100%">
      <Flex justify="space-between" mb={4}>
        <Heading size="md" color={textColor}>Case Timeline</Heading>
        <Button size="sm" onClick={() => setViewMode(viewMode === 'list' ? 'chart' : 'list')}>
          Switch to {viewMode === 'list' ? 'Chart' : 'List'} View
        </Button>
      </Flex>
      {viewMode === 'list' ? (
        <VStack align="stretch" spacing={4}>
          {listData.map((item, index) => (
            <Box key={index} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor={borderColor}>
              <HStack>
                <Icon as={FaCalendarAlt} color="blue.500" />
                <Text fontWeight="bold" color={textColor}>{item.date}</Text>
                <Text color={textColor}>{item.event}</Text>
              </HStack>
            </Box>
          ))}
        </VStack>
      ) : (
        <Box height="300px">
          <Line data={chartData} options={options} />
        </Box>
      )}
    </Box>
  );
};

const DepositionSummaryPreview = ({ onOpen }) => {
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const bgBox = useColorModeValue('blue.50', 'blue.900');
  const textBoxColor = useColorModeValue('gray.500', 'gray.300');

  return (
    <Box>
      <Heading size="md" mb={4} color={textColor}>Deposition Summary</Heading>
      <Box border="1px" borderColor="gray.200" borderRadius="md" p={4} mb={4} maxHeight="300px" overflowY="auto">
        <Text fontSize="sm" mb={2} color={textColor}>
          <strong>Deponent:</strong> John Doe
        </Text>
        <Text fontSize="sm" mb={2} color={textColor}>
          <strong>Date:</strong> July 15, 2023
        </Text>
        <Text fontSize="sm" mb={4} color={textColor}>
          <strong>Duration:</strong> 4 hours 30 minutes
        </Text>
        <Heading size="sm" mb={2} color={textColor}>Key Points:</Heading>
        <UnorderedList spacing={2} color={textColor}>
          <ListItem>Witness confirmed presence at the meeting on June 1st</ListItem>
          <ListItem>Described the contract negotiation process in detail</ListItem>
          <ListItem>Admitted to receiving the amended agreement via email</ListItem>
          <ListItem>Could not recall specific details about the July 10th phone call</ListItem>
        </UnorderedList>
        <Heading size="sm" mt={4} mb={2} color={textColor}>Important Quotes:</Heading>
        <VStack align="stretch" spacing={2}>
          <Box p={2} bg={bgBox} borderRadius="md">
            <Text fontSize="sm" color={textBoxColor}>"I distinctly remember signing the contract on June 15th, not July 1st as previously stated."</Text>
            <Text fontSize="xs" color={textBoxColor}>Page 23, Line 15</Text>
          </Box>
          <Box p={2} bg={bgBox} borderRadius="md">
            <Text fontSize="sm" color={textBoxColor}>"To the best of my knowledge, we never discussed the clause regarding intellectual property rights during our meetings."</Text>
            <Text fontSize="xs" color={textBoxColor}>Page 45, Line 7</Text>
          </Box>
          <Box p={2} bg={bgBox} borderRadius="md">
            <Text fontSize="sm" color={textBoxColor}>"I can't recall the exact time of the phone call, but I'm certain it was after lunch on July 10th."</Text>
            <Text fontSize="xs" color={textBoxColor}>Page 78, Line 22</Text>
          </Box>
        </VStack>
      </Box>
      <DemoButton colorScheme="blue" size="sm" onClick={onOpen}>View Full Summary</DemoButton>
    </Box>
  );
};

const AutomatedFileBriefPreview = ({ onOpen }) => {
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const bgBox = useColorModeValue('white', 'gray.700');

  return (
    <Box>
      <Heading size="md" mb={4} color={textColor}>Automated File Brief</Heading>
      <Box border="1px" borderColor="gray.200" borderRadius="md" p={4} mb={4} maxHeight="300px" overflowY="auto" bg={bgBox}>
        <Heading size="sm" mb={2} color={textColor}>Contract_v2.docx</Heading>
        <Text fontSize="sm" mb={4} color={textColor}>Last modified: July 20, 2023</Text>
        <VStack align="stretch" spacing={4}>
          <Box>
            <Heading size="xs" mb={1} color={textColor}>Document Type:</Heading>
            <Badge colorScheme="blue">Legal Contract</Badge>
          </Box>
          <Box>
            <Heading size="xs" mb={1} color={textColor}>Key Parties:</Heading>
            <HStack>
              <Badge colorScheme="green">ABC Corp</Badge>
              <Badge colorScheme="green">XYZ Inc</Badge>
            </HStack>
          </Box>
          <Box>
            <Heading size="xs" mb={1} color={textColor}>Main Topics:</Heading>
            <HStack>
              <Badge colorScheme="purple">Intellectual Property</Badge>
              <Badge colorScheme="purple">Non-Disclosure</Badge>
              <Badge colorScheme="purple">Payment Terms</Badge>
            </HStack>
          </Box>
          <Box>
            <Heading size="xs" mb={1} color={textColor}>Summary:</Heading>
            <Text fontSize="sm" color={textColor}>
              This document is the second version of a contract between ABC Corp and XYZ Inc. 
              It primarily deals with intellectual property rights, non-disclosure agreements, 
              and payment terms. Key changes from the previous version include updated clauses 
              on dispute resolution and termination conditions.
            </Text>
          </Box>
        </VStack>
      </Box>
      <DemoButton colorScheme="blue" size="sm" onClick={onOpen}>Generate Full Brief</DemoButton>
    </Box>
  );
};

const SmartFileTaggingPreview = () => {
  const [tags, setTags] = useState(['Contract', 'Intellectual Property', '2023']);
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const bgBox = useColorModeValue('white', 'gray.700');

  const addTag = (newTag) => {
    setTags([...tags, newTag]);
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  return (
    <Box>
      <Heading size="md" mb={4} color={textColor}>Smart File Tagging</Heading>
      <Box border="1px" borderColor="gray.200" borderRadius="md" p={4} mb={4} bg={bgBox}>
        <Heading size="sm" mb={2} color={textColor}>Contract_v2.docx</Heading>
        <Text fontSize="sm" mb={4} color={textColor}>Apply and manage tags for easy categorization and retrieval</Text>
        <HStack spacing={2} mb={4} flexWrap="wrap">
          {tags.map((tag, index) => (
            <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="blue">
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => removeTag(tag)} />
            </Tag>
          ))}
        </HStack>
        <Flex>
          <Input placeholder="Add new tag" size="sm" mr={2} />
          <Button size="sm" colorScheme="blue" onClick={() => addTag('New Tag')}>
            Add Tag
          </Button>
        </Flex>
      </Box>
      <VStack align="stretch" spacing={2}>
        <Heading size="sm" mb={2} color={textColor}>Suggested Tags:</Heading>
        <HStack spacing={2}>
          <Tag size="md" borderRadius="full" variant="outline" colorScheme="green" cursor="pointer" onClick={() => addTag('Legal')}>
            Legal
          </Tag>
          <Tag size="md" borderRadius="full" variant="outline" colorScheme="green" cursor="pointer" onClick={() => addTag('Confidential')}>
            Confidential
          </Tag>
          <Tag size="md" borderRadius="full" variant="outline" colorScheme="green" cursor="pointer" onClick={() => addTag('High Priority')}>
            High Priority
          </Tag>
        </HStack>
      </VStack>
    </Box>
  );
};

const DiscoDraftPreview = ({ onOpen }) => {
  const [selectedType, setSelectedType] = useState('rfa');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const bgBox = useColorModeValue('white', 'gray.700');

  const requestTypes = {
    rfa: 'Request for Admission',
    rfp: 'Request for Production',
    rog: 'Interrogatory'
  };

  return (
    <Box>
      <Heading size="md" mb={4} color={textColor}>DiscoDraft</Heading>
      <Tabs onChange={(index) => setSelectedType(Object.keys(requestTypes)[index])} mb={4}>
        <TabList>
          {Object.keys(requestTypes).map(type => (
            <Tab key={type}>{requestTypes[type]}</Tab>
          ))}
        </TabList>
      </Tabs>
      <Box border="1px" borderColor="gray.200" borderRadius="md" p={4} mb={4} height="250px" overflowY="auto" bg={bgBox}>
        <Text fontSize="sm" mb={4} color={textColor}>Generated {requestTypes[selectedType]}:</Text>
        <OrderedList spacing={3} color={textColor}>
          <ListItem>
            Admit that you were present at the meeting held on June 15, 2023, at the offices of ABC Corp.
          </ListItem>
          <ListItem>
            Admit that you received the email dated July 1, 2023, containing the amended contract terms.
          </ListItem>
          <ListItem>
            Admit that you signed the contract titled "Intellectual Property Agreement" on July 10, 2023.
          </ListItem>
          <ListItem>
            Admit that you were informed of the change in delivery schedule as outlined in the memo dated August 5, 2023.
          </ListItem>
          <ListItem>
            Admit that you participated in the conference call held on September 1, 2023, discussing project milestones.
          </ListItem>
        </OrderedList>
      </Box>
      <HStack spacing={4}>
        <DemoButton colorScheme="blue" size="sm" onClick={onOpen}>Generate More</DemoButton>
        <DemoButton colorScheme="green" size="sm" onClick={onOpen}>Export to Word</DemoButton>
      </HStack>
    </Box>
  );
};

const Features = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const features = [
    {
      title: "Intelligent Statement Search",
      description: "Rapidly locate critical information across all case files with pinpoint accuracy.",
      icon: FaSearch,
      preview: SearchPreview,
      id: "feature-search"
    },
    {
      title: "Comprehensive Case Summary",
      description: "Analyze depositions, documents, and discovery materials with AI-powered precision.",
      icon: FaFileAlt,
      preview: CaseAnalysisPreview,
      id: "feature-summary"
    },
    {
      title: "Automated Inconsistency Detection",
      description: "Catch discrepancies in testimonies and documents before opposing counsel does.",
      icon: FaBalanceScale,
      preview: InconsistencyPreview,
      id: "feature-inconsistency"
    },
    {
      title: "Dynamic Timeline Creation",
      description: "Automatically generate interactive chronologies, spot patterns, and present compelling narratives with ease.",
      icon: FaChartLine,
      preview: TimelinePreview,
      id: "feature-timeline"
    },
    {
      title: "Deposition Summaries",
      description: "Quickly generate and review deposition summaries, saving hours of manual review time.",
      icon: FaCalendarAlt,
      preview: DepositionSummaryPreview,
      id: "feature-deposition"
    },
    {
      title: "Automated File Briefs",
      description: "Instantly create structured summaries of documents, extracting key insights and critical information.",
      icon: FaFileSignature,
      preview: AutomatedFileBriefPreview,
      id: "feature-file-briefs"
    },
    {
      title: "Smart File Tagging",
      description: "Efficiently organize and retrieve documents, saving time in document management.",
      icon: FaTag,
      preview: SmartFileTaggingPreview,
      id: "feature-tagging"
    },
    {
      title: "DiscoDraft",
      description: "Streamline the creation of discovery requests, reducing drafting time and ensuring thoroughness.",
      icon: FaPencilAlt,
      preview: DiscoDraftPreview,
      id: "feature-discodraft"
    }
  ];

  return (
    <Box py={20}>
      <Container maxW="1200px" px={6}>
        <Heading as="h2" size="2xl" mb={20} textAlign="center">
          Features
        </Heading>
        <VStack spacing={20}>
          {features.map((feature, index) => (
            <MotionBox
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <FeatureSection
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
                isEven={index % 2 !== 0}
                onOpen={onOpen}
                id={feature.id} // Pass the id to the FeatureSection
              >
                <feature.preview onOpen={onOpen} />
              </FeatureSection>
            </MotionBox>
          ))}
        </VStack>
      </Container>
    </Box>
  );
};

export default Features;
