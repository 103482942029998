import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import LeadCollectionModal from './LeadCollectionModal';

const withLeadCollection = (WrappedComponent) => {
  return (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
        <WrappedComponent {...props} onClick={onOpen} />
        <LeadCollectionModal isOpen={isOpen} onClose={onClose} />
      </>
    );
  };
};

export default withLeadCollection;