import React, { useState } from 'react';
import {
  Box, Heading, Text, Button, VStack, Container, Flex, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText, Icon, useColorModeValue, Badge, HStack, Input, InputGroup, InputLeftElement, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRocket, FaFile, FaHashtag, FaUser, FaComment, FaExclamationCircle, FaCalendarAlt, FaSearch, FaEye, FaFileAlt, FaChartBar, FaPlusCircle, FaCloudUploadAlt, FaDropbox, FaGoogleDrive, FaLaptop, FaFilePdf } from 'react-icons/fa';
import { SiMicrosoftonedrive } from 'react-icons/si';
import withLeadCollection from './withLeadCollection';

const MotionBox = motion(Box);
const DemoButton = withLeadCollection(Button);

const DashboardPreview = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const bgColor = useColorModeValue('white', 'gray.800');
  const statColor = useColorModeValue('gray.600', 'gray.200');
  const accentColor = useColorModeValue('blue.500', 'blue.200');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');

  const stats = [
    { icon: FaFile, label: 'Files', number: 42, helpText: 'Total size:', badge: '1.2GB' },
    { icon: FaHashtag, label: 'Topics', number: 15, helpText: 'Most common:', badge: 'IP' },
    { icon: FaUser, label: 'Speakers', number: 8, helpText: 'Most common:', badge: 'Q. Tarantino' },
    { icon: FaComment, label: 'Statements', number: 253, helpText: 'No. of Files:', badge: 12 },
    { icon: FaExclamationCircle, label: 'Inconsistencies', number: 6, helpText: 'Citations:', badge: 18 },
    { icon: FaCalendarAlt, label: 'Timeline', number: 22, helpText: 'Most common topic:', badge: 'Screenplay Rights' },
  ];

  const timelineEvents = [
    { date: '2023-01-01', content: 'Project Kickoff' },
    { date: '2023-02-15', content: 'Design Phase' },
    { date: '2023-03-10', content: 'Development Begins' },
    { date: '2023-04-20', content: 'Testing and QA' },
  ];

  const inconsistencies = [
    { id: 1, text: "Inconsistency regarding Tarantino's rights to the Pulp Fiction screenplay", topic: "Screenplay Rights" },
    { id: 2, text: "Inconsistency regarding whether Tarantino kept his Pulp Fiction NFT plans secret from Miramax", topic: "NFT Plans" },
  ];

  const renderDashboard = () => (
    <SimpleGrid columns={{ base: 2, md: 3 }} spacing={2} fontSize="sm">
      {stats.map((stat, index) => (
        <Stat key={index} p={2} borderRadius="md" bg={bgColor} boxShadow="sm" _hover={{ bg: hoverBg, transform: 'translateY(-2px)' }} transition="all 0.3s">
          <Flex align="center" mb={1}>
            <Icon as={stat.icon} mr={1} color={accentColor} />
            <StatLabel fontSize="xs">{stat.label}</StatLabel>
          </Flex>
          <StatNumber fontSize="md" fontWeight="bold" color={statColor}>
            {stat.number}
          </StatNumber>
          <StatHelpText fontSize="xs">
            {stat.helpText} <Badge colorScheme="blue" fontSize="2xs">{stat.badge}</Badge>
          </StatHelpText>
        </Stat>
      ))}
    </SimpleGrid>
  );

  const renderTimeline = () => (
    <VStack align="stretch" spacing={2} fontSize="sm">
      {timelineEvents.map((event, index) => (
        <Flex key={index} justifyContent={index % 2 === 0 ? 'flex-start' : 'flex-end'}>
          <Box p={2} borderRadius="md" boxShadow="sm" bg={bgColor} maxWidth="80%" _hover={{ bg: hoverBg, transform: 'translateY(-2px)' }} transition="all 0.3s">
            <Text fontWeight="bold" fontSize="xs">{event.date}</Text>
            <Text fontSize="xs">{event.content}</Text>
          </Box>
        </Flex>
      ))}
    </VStack>
  );

  const renderCaseSummary = () => (
    <Box className="section" p={4} borderRadius="md" boxShadow="sm" bg={bgColor} fontSize="sm" _hover={{ bg: hoverBg, transform: 'translateY(-2px)' }} transition="all 0.3s">
      <Heading as="h2" size="sm" mb={2} color={accentColor}>Legal Dispute Overview</Heading>
      <Text fontSize="xs">The legal dispute involves Miramax, LLC against Quentin Tarantino and Visiona Romantica, Inc. regarding the sale of seven exclusive scenes from the film "Pulp Fiction" as Non-Fungible Tokens (NFTs).</Text>
      <Heading as="h3" size="xs" mt={2} mb={1} color={accentColor}>Key Entities:</Heading>
      <ul style={{ paddingLeft: '20px', fontSize: '10px' }}>
        <li>Miramax, LLC (Plaintiff)</li>
        <li>Quentin Tarantino (Defendant)</li>
        <li>Visiona Romantica, Inc. (Defendant)</li>
      </ul>
      <Skeleton height="20px" mt={2} />
      <Skeleton height="20px" mt={1} />
    </Box>
  );

  const renderSearch = () => (
    <VStack align="stretch" spacing={2}>
      <InputGroup size="sm">
        <InputLeftElement pointerEvents="none">
          <FaSearch color="gray.300" />
        </InputLeftElement>
        <Input placeholder="Search..." />
      </InputGroup>
      <Tabs size="sm" variant="soft-rounded" colorScheme="blue">
        <TabList>
          <Tab fontSize="xs">Speakers</Tab>
          <Tab fontSize="xs">Statements</Tab>
          <Tab fontSize="xs">Topics</Tab>
          <Tab fontSize="xs">Files</Tab>
          <Tab fontSize="xs">Inconsistencies</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={2}>
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" />
          </TabPanel>
          <TabPanel p={2}>
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" />
          </TabPanel>
          <TabPanel p={2}>
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" />
          </TabPanel>
          <TabPanel p={2}>
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" />
          </TabPanel>
          <TabPanel p={2}>
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );

  const renderAddFiles = () => (
    <VStack spacing={2} align="stretch">
      <Flex justifyContent="space-around" mb={2}>
        {[
          { icon: FaLaptop, label: 'My Device' },
          { icon: FaDropbox, label: 'Dropbox' },
          { icon: FaGoogleDrive, label: 'Google Drive' },
          { icon: SiMicrosoftonedrive, label: 'OneDrive' }
        ].map((item, index) => (
          <Tooltip key={index} label={item.label}>
            <VStack spacing={1} _hover={{ color: accentColor }} transition="all 0.3s" cursor="pointer">
              <Icon as={item.icon} boxSize={4} color={accentColor} />
              <Text fontSize="xs">{item.label}</Text>
            </VStack>
          </Tooltip>
        ))}
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        borderStyle="dashed"
        borderColor={accentColor}
        borderRadius="md"
        p={2}
        _hover={{ bg: hoverBg }}
        transition="all 0.3s"
        cursor="pointer"
      >
        <Icon as={FaCloudUploadAlt} boxSize={6} color={accentColor} mb={1} />
        <Text fontSize="xs" textAlign="center">
          Drag and drop files here, or click to select files
        </Text>
      </Flex>
    </VStack>
  );

  const renderViewFiles = () => (
    <VStack align="stretch" spacing={2}>
      <InputGroup size="sm">
        <InputLeftElement pointerEvents="none">
          <FaSearch color="gray.300" />
        </InputLeftElement>
        <Input placeholder="Search files..." />
      </InputGroup>
      <HStack spacing={2}>
        <Badge colorScheme="blue">PDF</Badge>
        <Badge colorScheme="green">Word</Badge>
        <Badge colorScheme="red">Excel</Badge>
        <Badge colorScheme="purple">Image</Badge>
      </HStack>
      <VStack align="stretch" spacing={2}>
        {[1, 2, 3].map((_, index) => (
          <Flex key={index} align="center" bg={bgColor} p={2} borderRadius="md" boxShadow="sm" _hover={{ bg: hoverBg }} transition="all 0.3s">
            <Icon as={FaFilePdf} boxSize={4} color="red.500" mr={2} />
            <Box flex="1">
              <Skeleton height="12px" width="80%" mb={1} />
              <Skeleton height="10px" width="60%" />
            </Box>
          </Flex>
        ))}
      </VStack>
    </VStack>
  );

  const renderInconsistencies = () => (
    <VStack align="stretch" spacing={2} fontSize="sm">
      {inconsistencies.map((inconsistency) => (
        <Box key={inconsistency.id} p={2} borderRadius="md" boxShadow="sm" bg={bgColor} _hover={{ bg: hoverBg, transform: 'translateY(-2px)' }} transition="all 0.3s">
          <Flex justify="space-between" align="center" mb={1}>
            <Icon as={FaExclamationCircle} color="orange.500" mr={2} />
            <Text fontWeight="bold" fontSize="xs">{inconsistency.text}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Badge colorScheme="orange" fontSize="2xs">{inconsistency.topic}</Badge>
            <HStack spacing={1}>
              <Badge colorScheme="blue" fontSize="2xs">3 Citations</Badge>
              <Badge colorScheme="green" fontSize="2xs">2 Documents</Badge>
            </HStack>
          </Flex>
        </Box>
      ))}
    </VStack>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return renderDashboard();
      case 'timeline':
        return renderTimeline();
      case 'caseSummary':
        return renderCaseSummary();
      case 'search':
        return renderSearch();
      case 'addFiles':
        return renderAddFiles();
      case 'viewFiles':
        return renderViewFiles();
      case 'inconsistencies':
        return renderInconsistencies();
      default:
        return null;
    }
  };

  return (
    <MotionBox
      bg={bgColor}
      p={4}
      borderRadius="lg"
      boxShadow="xl"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      overflow="hidden"
      position="relative"
      height="320px"
      width="100%"
    >
      <Flex height="100%">
        <VStack spacing={4} align="center" mr={4}>
          {[
            { icon: FaChartBar, tab: 'dashboard' },
            { icon: FaPlusCircle, tab: 'addFiles' },
            { icon: FaEye, tab: 'viewFiles' },
            { icon: FaExclamationCircle, tab: 'inconsistencies' },
            { icon: FaSearch, tab: 'search' },
            { icon: FaCalendarAlt, tab: 'timeline' },
            { icon: FaFileAlt, tab: 'caseSummary' },
          ].map((item, index) => (
            <Tooltip key={index} label={item.tab} placement="right">
              <Box>
                <Icon
                  as={item.icon}
                  boxSize={5}
                  color={activeTab === item.tab ? accentColor : 'gray.400'}
                  cursor="pointer"
                  onClick={() => setActiveTab(item.tab)}
                  _hover={{ color: accentColor }}
                  transition="all 0.3s"
                />
              </Box>
            </Tooltip>
          ))}
        </VStack>
        <Box flex={1} overflowY="auto" pr={2}>
          <AnimatePresence mode="wait">
            <MotionBox
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {renderTabContent()}
            </MotionBox>
          </AnimatePresence>
        </Box>
      </Flex>
    </MotionBox>
  );
};

function Hero() {
    return (
      <Box 
        py={20} 
        bgGradient={useColorModeValue("linear(to-r, blue.50, blue.100)", "linear(to-r, gray.700, gray.800)")}
        pt={{ base: '100px', md: '120px' }}
      >
        <Container maxW="1200px">
          <Flex direction={{ base: 'column', lg: 'row' }} align="center">
            <VStack spacing={8} alignItems="flex-start" flex={1} mb={{ base: 10, lg: 0 }}>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Heading as="h1" size="2xl" color={useColorModeValue("gray.800", "white")}>
                  Accelerate Document Review by 10x with AI
                </Heading>
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Text fontSize="xl" color={useColorModeValue("gray.700", "gray.200")}>
                  Uncover Critical Case Insights in Minutes, Not Months. Join Top Firms Revolutionizing Litigation with Lexlink AI.
                </Text>
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <DemoButton
                  as={motion.button}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  colorScheme="blue"
                  size="lg"
                  leftIcon={<FaRocket />}
                  boxShadow="md"
                >
                  Request a Demo
                </DemoButton>
              </MotionBox>
            </VStack>
            <Box flex={1} ml={{ base: 0, lg: 10 }}>
              <DashboardPreview />
            </Box>
          </Flex>
        </Container>
      </Box>
    );
  }
  
  export default Hero;
