import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const headingColor = useColorModeValue('gray.800', 'white');

  const sections = [
    {
      title: "INTRODUCTION",
      content: `This Privacy Policy describes how Perjury AI, Inc., doing business as Lexlink.ai ("Lexlink AI", "we", "us" or "our") handles personal information that we collect through our digital properties that link to this policy (collectively, the "Website").

The Website and Lexlink AI’s artificial intelligence platform (the "Lexlink AI Platform") are intended exclusively for use by legal professionals in their business capacity. We do not offer products or services for use by individuals for their personal, family, or household purposes. Accordingly, we treat all personal information we collect in connection with the Website as pertaining to individuals in their capacities as representatives of the relevant business and not their individual personal capacities.

Importantly, this policy does not apply to information that Lexlink AI processes on behalf of legal professionals while providing the Lexlink AI Platform to them. Our use of information that we process on behalf of our clients is governed by our agreements with them. If you have concerns regarding your personal information that we process on behalf of a client, please direct your concerns to them.`
    },
    {
      title: "PERSONAL INFORMATION WE COLLECT",
      content: `Information you provide to us. Personal information you may provide to us through the Website or otherwise includes:
- Contact data, such as your first and last name, salutation, email address, mailing address, professional title and company name, and phone number.
- Communications data based on our exchanges with you, including when you contact us through the Website, social media, or otherwise.
- Research data, such as your responses to surveys that we might ask you to complete for research purposes related to our business.
- Marketing data, such as your preferences for receiving our marketing communications and details about your engagement with them.
- Profile data, such as the username and password that you may set to establish an online account on the Website.
- Payment data needed to complete transactions, including payment card information or bank account number. Payment data is collected directly by our payment processor, Stripe, as described further below.
- Other data not specifically listed here, which we will use as described in this policy or as otherwise disclosed at the time of collection.

Third-party sources. We may combine personal information we receive from you with personal information we obtain from other sources, such as:
- Public sources, such as government agencies, public records, social media platforms, and other publicly available sources.
- Data providers, such as information services and data licensors that provide demographic and other information that we use to provide the Website.
- Marketing partners, such as joint marketing partners and event co-sponsors.

Automatic data collection. We, our service providers, and our business partners may automatically log certain data about you, your computer or mobile device, and your interaction over time with the Website, our communications and other online services, such as:
- Device data, such as your computer or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers, language settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 5G), and general location information such as city, state or general geographic area.
- Online activity data, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Website, navigation paths between pages or screens, information about your activity on a page or screen, access times and duration of access, and whether you have opened our emails or clicked links within them.
- Communication interaction data such as your interactions with our email, or other communications (e.g., whether you open and/or forward emails) – we may do this through use of pixel tags (which are also known as clear GIFs), which may be embedded invisibly in our emails.

Cookies and similar technologies. Some of the automatic collection described above is facilitated by the following technologies:
- Cookies, which are small text files that websites store on user devices and that allow web servers to record users’ web browsing activities and remember their submissions, preferences, and login status as they navigate a site. Cookies used on our sites include both “session cookies” that are deleted when a session ends, “persistent cookies” that remain longer, “first party” cookies that we place and “third party” cookies that our service providers place.
- Local storage technologies, like HTML5, that provide cookie-equivalent functionality but can store larger amounts of data on your device outside of your browser in connection with specific applications.
- Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.

We may use cookies to allow the technical operation of the Website, enhance the functionality of the Website, and help us understand user activity on the Website (including which pages are most and least visited and how visitors move around the Website, as well as user interactions with our emails). We currently do not support “Do Not Track” requests.`
    },
    {
      title: "HOW WE USE YOUR PERSONAL INFORMATION",
      content: `We may use your personal information for the following purposes (or in other ways that we ask you for at the time we collect it):

Website delivery and operations. We may use your personal information to:
- Provide, operate and improve the Website and our business;
- Communicate with you about the Website, including by sending announcements, updates, security alerts, and support and administrative messages;
- Understand your needs and interests, and personalize your experience with the Website and our communications; and
- Provide support for the Website, and respond to your requests, questions and feedback.

Research and development. We may use your personal information for research and development purposes, including to analyze and improve the Website and our business. As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect. We convert personal information into anonymous or de-identified data by removing information that makes the data personally identifiable to you. We may use this anonymous or de-identified data and share it with third parties for our lawful business purposes, including to analyze and improve the Website and promote our business.

Marketing. We and our service providers may collect and use your personal information to send you direct marketing communications. You may opt-out of our marketing communications as described in the Opt-out of marketing section below.

Compliance and protection. We may use your personal information to:
- Comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;
- Protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);
- Audit our internal processes for compliance with legal and contractual requirements or our internal policies;
- Enforce the terms and conditions that govern the Website; and
- Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.

With your consent. In some cases, we may specifically ask for your consent to collect, use or share your personal information, such as when required by law.`
    },
    {
      title: "HOW WE SHARE YOUR PERSONAL INFORMATION",
      content: `We may share your personal information to the extent necessary with the following parties (as well as otherwise described in this policy or at the time of collection):

- Service providers. Third parties that provide services on our behalf or help us operate the Website or our business (such as hosting, information technology, customer support, email delivery, customer research, marketing, and website analytics).
- Payment processors. Any payment card information you use to make a purchase on the Website is collected and processed directly by our payment processor, Stripe. Stripe may use your payment data in accordance with its privacy policy, https://stripe.com/privacy.
- Third parties designated by you. We may share your personal data with third parties where you have instructed us or provided your consent to do so. We will share personal information that is needed for these other companies to provide the services that you have requested.
- Business and marketing partners. Third parties with whom we co-sponsor events, with whom we jointly offer products or services, or whose products or services may be of interest to you.
- Professional advisors. Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they provide to us.
- Legal authorities. Law enforcement and governmental authorities as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.
- Business transferees. Buyers and other relevant investors or participants in business transactions (including negotiations of, or due diligence for, such transactions) involving a corporate sale, merger, consolidation, acquisition, reorganization, or other disposition of all or any part of Lexlink AI.`
    },
    {
      title: "YOUR CHOICES",
      content: `You have a number of choices when it comes to the personal information you provide to Lexlink AI:
- Access or update your information. If you have registered for an account with us through the Website, you may review and update certain account information by logging into the account.
- Opt-out of marketing communications. You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. Even if you choose to opt-out of marketing-related emails, you may continue to receive necessary service-related and other non-marketing emails.
- Declining to provide information. We need to collect personal information to provide certain services. If you choose not to provide the information we identify as required or mandatory, we may not be able to provide you those services.`
    },
    {
      title: "OTHER SITES AND SERVICES",
      content: `The Website may contain links to websites, mobile applications, and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions. We encourage you to read the privacy policies of the other websites, mobile applications and online services that you use.`
    },
    {
      title: "SECURITY",
      content: `We employ technical, organizational and physical safeguards that are designed to protect the personal information we collect (including with respect to personnel, facilities, hardware and software, storage and networks, access controls, monitoring and logging, vulnerability and breach detection, incident response, and encryption). However, security risk is inherent in all internet and information technologies, and we cannot guarantee the security of your personal information.

In the case of a data security breach involving your personal information, we notify you without undue delay after we become aware of such breach, as required by applicable law.`
    },
    {
      title: "INTERNATIONAL DATA TRANSFERS",
      content: `We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be stored or transferred to other locations where privacy laws may not be as protective as those in your state, province, or country. However, if you are a business customer working with us, we will do our best to work with you to adhere to any of your statutory requirements regarding data localization (location of your stored data), including any such requirements imposed by your applicable governing bodies.

If you are considered a “data subject” under the EU General Data Protection Regulation (GDPR), then we will handle your personal information in accordance with the GDPR; however, we remind you that this policy is intended for individuals acting in their capacity as a representative of a business customer of Lexlink AI.`
    },
    {
      title: "CHILDREN",
      content: `The Website is not intended for use by anyone under 18 years of age. If you are a parent or guardian of a child from whom you believe we have collected personal information in a manner prohibited by law, please contact us. If we learn that we have collected personal information through the Website from a child without the consent of the child’s parent or guardian as required by law, we will comply with applicable legal requirements to delete the information.`
    },
    {
      title: "CHANGES TO THIS PRIVACY POLICY",
      content: `We reserve the right to modify this policy at any time. If we make material changes to it, we will notify you by updating the date of this policy and posting it on the Website or other appropriate means. Any changes to this policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Website after the effective date of any modified policy indicates your acceptance of the modified policy.`
    },
    {
      title: "CONTACT US",
      content: `To exercise your choices or if you have questions about this Privacy Policy or our privacy practices, please contact us at:

Perjury AI, Inc.
4160 Temescal Canyon Rd Ste 401
Corona, CA 92883
United States
hello@lexlink.ai`
    },
  ];

  return (
    <Box bg={bgColor} minHeight="100vh" py={20}>
      <Container maxW="1200px">
        <VStack spacing={8} as="section" textAlign="center" mb={16}>
          <Heading
            as="h1"
            size="2xl"
            bgGradient="linear(to-r, blue.400, purple.500)"
            bgClip="text"
            letterSpacing="tight"
          >
            Privacy Policy
          </Heading>
          <Text fontSize="xl" color={textColor}>
            Last updated July 16, 2024
          </Text>
        </VStack>

        <Accordion allowMultiple>
          {sections.map((section, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight="bold" color={headingColor}>
                    {section.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text color={textColor} whiteSpace="pre-wrap">
                  {section.content}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
