import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  Icon,
  Flex,
  useToast
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaRocket, FaBalanceScale, FaLaptop, FaMapMarkerAlt } from 'react-icons/fa';

const MotionBox = motion(Box);

const Careers = () => {
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      resume: '',
      description: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await fetch('https://yyj8c26wya.execute-api.us-east-1.amazonaws.com/dev/mailchimp/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        toast({
          title: "Application submitted.",
          description: "We've received your application and will be in touch soon.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFormData({ firstName: '', lastName: '', email: '', resume: '', description: '' });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: "An error occurred.",
        description: "Unable to submit your application. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg={bgColor} py={20}>
      <Container maxW="1200px">
        <VStack spacing={12} as="section" textAlign="center" mb={16}>
          <Heading 
            as="h1" 
            size="3xl" 
            bgGradient="linear(to-r, blue.400, purple.500)" 
            bgClip="text"
            letterSpacing="tight"
          >
            Join Our Mission to Democratize Justice
          </Heading>
          <Text fontSize="xl" maxW="800px" mx="auto" color={textColor}>
            At Lexlink AI, we're on a mission to democratize justice for all and establish integrity in the legal process. 
            Founded in 2023 by Jaspal Basra and Steven Lacy, we're driven by personal experiences with frivolous litigation 
            to revolutionize inconsistency detection and document review in the legal industry.
          </Text>
        </VStack>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mb={20}>
          <MotionBox
            whileHover={{ y: -5 }}
            transition={{ duration: 0.3 }}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              bg={cardBg}
              p={8}
              borderRadius="lg"
              boxShadow="xl"
              height="100%"
            >
              <Icon as={FaRocket} w={10} h={10} color="blue.500" mb={4} />
              <Heading as="h3" size="md" mb={2} textAlign="center">Innovative AI Solutions</Heading>
              <Text textAlign="center" color={textColor}>
                Develop cutting-edge AI technologies that transform the legal landscape.
              </Text>
            </Flex>
          </MotionBox>

          <MotionBox
            whileHover={{ y: -5 }}
            transition={{ duration: 0.3 }}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              bg={cardBg}
              p={8}
              borderRadius="lg"
              boxShadow="xl"
              height="100%"
            >
              <Icon as={FaBalanceScale} w={10} h={10} color="blue.500" mb={4} />
              <Heading as="h3" size="md" mb={2} textAlign="center">Impact-Driven Work</Heading>
              <Text textAlign="center" color={textColor}>
                Make a real difference in the pursuit of justice and legal integrity.
              </Text>
            </Flex>
          </MotionBox>

          <MotionBox
            whileHover={{ y: -5 }}
            transition={{ duration: 0.3 }}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              bg={cardBg}
              p={8}
              borderRadius="lg"
              boxShadow="xl"
              height="100%"
            >
              <Icon as={FaLaptop} w={10} h={10} color="blue.500" mb={4} />
              <Heading as="h3" size="md" mb={2} textAlign="center">Remote-First Culture</Heading>
              <Text textAlign="center" color={textColor}>
                Enjoy the flexibility of remote work with a global team of innovators.
              </Text>
            </Flex>
          </MotionBox>
        </SimpleGrid>

        <Box bg={cardBg} p={10} borderRadius="lg" boxShadow="xl" mb={20}>
          <Heading as="h2" size="xl" mb={6} textAlign="center">Join Our Team</Heading>
          <Text fontSize="lg" mb={8} textAlign="center" color={textColor}>
            While we don't have specific job postings at the moment, we're always on the lookout for talented individuals 
            who are passionate about legal tech and AI. If you believe you can contribute to our mission, we'd love to hear from you!
          </Text>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input name="firstName" value={formData.firstName} onChange={handleInputChange} placeholder="Your First Name" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input name="lastName" value={formData.lastName} onChange={handleInputChange} placeholder="Your Last Name" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input name="email" type="email" value={formData.email} onChange={handleInputChange} placeholder="Your Email" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Resume Link</FormLabel>
                <Input name="resume" value={formData.resume} onChange={handleInputChange} placeholder="Link to your resume (Google Drive, Dropbox, etc.)" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>How can you contribute to Lexlink AI?</FormLabel>
                <Textarea name="description" value={formData.description} onChange={handleInputChange} placeholder="Describe your skills and how you can help us transform legal tech" />
              </FormControl>
              <Button type="submit" colorScheme="blue" size="lg" width="full" isLoading={isLoading}>Submit Application</Button>
            </VStack>
          </form>
        </Box>

        <Box textAlign="center">
          <Heading as="h2" size="xl" mb={6}>Our Locations</Heading>
          <Text fontSize="lg" mb={8} color={textColor}>
            While we're primarily a remote-first company, we maintain satellite offices for collaboration and innovation.
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Flex align="center" justify="center">
              <Icon as={FaMapMarkerAlt} w={6} h={6} color="blue.500" mr={2} />
              <Text fontSize="lg" fontWeight="bold">Southern California</Text>
            </Flex>
            <Flex align="center" justify="center">
              <Icon as={FaMapMarkerAlt} w={6} h={6} color="blue.500" mr={2} />
              <Text fontSize="lg" fontWeight="bold">Bay Area, California</Text>
            </Flex>
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
};

export default Careers;
