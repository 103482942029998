import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  useColorModeValue,
  Flex,
  Icon,
  Stack,
  Badge,
  SimpleGrid,
  Button
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaRocket, FaClock, FaLightbulb, FaChartLine, FaCalendar } from 'react-icons/fa';
import withLeadCollection from './withLeadCollection';

const MotionBox = motion(Box);
const DemoButton = withLeadCollection(Button);

const Feature = ({ icon, text }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={useColorModeValue('blue.100', 'blue.900')}
      >
        <Icon as={icon} color={useColorModeValue('blue.500', 'blue.200')} />
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

function CallToAction() {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.800');
  const gradientColor = useColorModeValue(
    'linear(to-r, blue.50, purple.50)',
    'linear(to-r, blue.900, purple.900)'
  );

  return (
    <Box bg={bgColor} position="relative" overflow="hidden">
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="6px"
        bgGradient="linear(to-r, blue.400, purple.500)"
      />
      <Container maxW="1200px" py={20}>
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <VStack spacing={8} alignItems="center" textAlign="center">
            <Badge colorScheme="blue" fontSize="md" px={3} py={1} rounded="full">
              Exclusive Offer
            </Badge>
            <Heading as="h2" size="2xl" mb={4}>
              Experience the Future of Legal Practice
            </Heading>
            <Text fontSize="xl" maxW="2xl">
              Join the vanguard of law firms harnessing the power of Lexlink AI. Secure your competitive edge in an increasingly digital legal landscape.
            </Text>
            <MotionBox
              bg={cardBg}
              p={8}
              rounded="xl"
              shadow="xl"
              width="full"
              maxW="3xl"
              mt={8}
              transition="all 0.3s"
              _hover={{
                transform: 'translateY(-5px)',
                boxShadow: '2xl',
                bgGradient: gradientColor,
              }}
            >
              <VStack spacing={6} align="stretch">
                <Heading as="h3" size="lg" textAlign="center">
                  Unleash the Lexlink AI Advantage
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <Feature icon={FaClock} text="Slash case preparation time by up to 70%" />
                  <Feature icon={FaLightbulb} text="Uncover game-changing case insights" />
                  <Feature icon={FaChartLine} text="Elevate win rates with AI-driven strategies" />
                  <Feature icon={FaRocket} text="Leave competitors in the dust" />
                </SimpleGrid>
                <DemoButton
                  colorScheme="blue"
                  size="lg"
                  height="16"
                  px="8"
                  fontSize="md"
                  fontWeight="bold"
                  leftIcon={<FaCalendar />}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'xl',
                  }}
                  transition="all 0.3s"
                >
                  Schedule Your Personalized Demo
                </DemoButton>
                <Text fontSize="sm" textAlign="center" fontWeight="medium">
                  Limited slots available. Book your demo now to secure priority implementation and exclusive launch pricing.
                </Text>
              </VStack>
            </MotionBox>
          </VStack>
        </MotionBox>
      </Container>
    </Box>
  );
}

export default CallToAction;
