import React, { useState } from 'react';
import {
  Box, Container, Heading, Text, VStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
  useColorModeValue, Button, Flex, Icon, Input, InputGroup, InputLeftElement, Progress, Link,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure,
  FormControl, FormLabel, Textarea, Checkbox, HStack
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaQuestionCircle, FaArrowRight, FaShieldAlt, FaCogs, FaCloudUploadAlt, FaUsers, FaClock, FaDollarSign, FaSearch, FaEnvelope } from 'react-icons/fa';
import withLeadCollection from './withLeadCollection';

const MotionBox = motion(Box);
const MotionAccordionButton = motion(AccordionButton);
const MotionAccordionPanel = motion(AccordionPanel);
const DemoButton = withLeadCollection(Button);
const ContactButton = withLeadCollection(Button);

const FAQ = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({ name: '', email: '', company: '', message: '', subscribe: false });

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const accentColor = useColorModeValue('blue.500', 'blue.200');
  const itemBg = useColorModeValue('white', 'gray.800');
  const itemColor = useColorModeValue('gray.800', 'white');
  const hoverBg = useColorModeValue(
    'linear(to-r, blue.50, purple.50)',
    'linear(to-r, blue.900, purple.900)'
  );
  const activeBg = useColorModeValue(
    'linear(to-r, blue.100, purple.100)',
    'linear(to-r, blue.800, purple.800)'
  );
  const subTextColor = useColorModeValue('gray.600', 'gray.400');

  const faqs = [
    {
      question: "How secure is Lexlink AI?",
      answer: "Security is our top priority. Lexlink AI employs state-of-the-art encryption and complies with all major legal data protection standards, including GDPR and CCPA. We offer flexible deployment options, including on-premises solutions, to meet the strictest data security requirements of law firms.",
      icon: FaShieldAlt,
      relatedQuestions: [1, 2],
      helpfulPercentage: 95
    },
    {
      question: "Can Lexlink AI provide customized inconsistency reporting?",
      answer: "Absolutely. We understand that each case is unique. If you're looking for specific support or refutation of contentions around a particular topic, we can deliver a custom report the same day after all files have been processed. Our AI's adaptability ensures you get precisely the insights you need.",
      icon: FaCogs,
      relatedQuestions: [0, 2],
      helpfulPercentage: 88
    },
    {
      question: "Does Lexlink AI offer managed file upload services?",
      answer: "Yes, we do. We recognize that time is crucial in legal matters. If you prefer, you can simply send us your files, and our team will rapidly upload and process them for you. This managed service ensures a quick start to your case analysis without any technical hurdles on your end.",
      icon: FaCloudUploadAlt,
      relatedQuestions: [0, 1],
      helpfulPercentage: 92
    },
    {
      question: "Is there a limit to the number of files we can upload?",
      answer: "There is no limit. Whether you're dealing with hundreds or hundreds of thousands of documents, Lexlink AI is designed to process and analyze them all. Our scalable infrastructure ensures that even the most document-intensive cases can be handled efficiently.",
      icon: FaCloudUploadAlt,
      relatedQuestions: [2, 4],
      helpfulPercentage: 97
    },
    {
      question: "How does Lexlink AI handle storage and user seats?",
      answer: "We believe in transparent, user-friendly pricing. Lexlink AI does not charge for storage, regardless of the volume of documents. Additionally, we provide unlimited user seats for our customers. This approach ensures that your entire team can leverage our AI insights without additional costs.",
      icon: FaUsers,
      relatedQuestions: [3, 5],
      helpfulPercentage: 94
    },
    {
      question: "What kind of support does Lexlink AI provide?",
      answer: "We offer comprehensive support during regular business hours to ensure you get the most out of Lexlink AI. For larger cases or clients with specific needs, we also offer the option of 24/7 support. Our goal is to provide the level of assistance that best suits your firm's requirements.",
      icon: FaClock,
      relatedQuestions: [0, 4],
      helpfulPercentage: 91
    },
    {
      question: "What does Lexlink AI cost?",
      answer: "Our pricing model is designed to be accessible for firms of all sizes, from solo practitioners to large law firms. The cost is based on document processing, allowing for scalability and fairness. We encourage you to reach out for a personalized quote that aligns with your specific needs and case volume.",
      icon: FaDollarSign,
      relatedQuestions: [4, 5],
      helpfulPercentage: 89
    }
  ];

  const filteredFaqs = faqs.filter(faq => 
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    onClose();
  };

  return (
    <Box id="faq" bg={bgColor} position="relative" overflow="hidden" py={20}>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="6px"
        bgGradient="linear(to-r, blue.400, purple.500)"
      />
      <Container maxW="1200px">
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <VStack spacing={8} alignItems="center" textAlign="center">
            <Flex align="center">
              <Icon as={FaQuestionCircle} w={8} h={8} color={accentColor} mr={3} />
              <Heading as="h2" size="2xl">
                Frequently Asked Questions
              </Heading>
            </Flex>
            <Text fontSize="xl" maxW="2xl">
              Get answers to common questions about Lexlink AI and discover how our tailored solutions can elevate your legal practice.
            </Text>
            <InputGroup maxW="md">
              <InputLeftElement pointerEvents="none">
                <Icon as={FaSearch} color="gray.300" />
              </InputLeftElement>
              <Input 
                placeholder="Search FAQs" 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg={itemBg}
              />
            </InputGroup>
          </VStack>
          <VStack spacing={4} mt={12} align="stretch">
            <Accordion allowToggle index={expandedIndex} onChange={handleToggle}>
              <AnimatePresence>
                {filteredFaqs.map((faq, index) => (
                  <AccordionItem key={index} border="none" mb={4}>
                    <MotionAccordionButton
                      bg={expandedIndex === index ? activeBg : itemBg}
                      color={itemColor}
                      p={5}
                      borderRadius="lg"
                      transition="all 0.3s"
                      fontWeight="semibold"
                      _hover={{
                        bgGradient: hoverBg,
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                      }}
                    >
                      <Flex align="center" flex="1" textAlign="left">
                        <Icon
                          as={faq.icon}
                          color={accentColor}
                          mr={3}
                          fontSize="24px"
                        />
                        <Text>{faq.question}</Text>
                      </Flex>
                      <AccordionIcon />
                    </MotionAccordionButton>
                    <MotionAccordionPanel
                      pb={4}
                      bg={itemBg}
                      borderRadius="md"
                      mt={2}
                      p={5}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Text mb={4}>{faq.answer}</Text>
                      <ContactButton
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaEnvelope />}
                        mb={4}
                      >
                        Contact Us
                      </ContactButton>
                      <Progress value={faq.helpfulPercentage} colorScheme="green" size="sm" mb={2} />
                      <Text fontSize="sm" color={subTextColor} mb={4}>
                        {faq.helpfulPercentage}% of users found this helpful
                      </Text>
                      <Box>
                        <Text fontWeight="bold" mb={2}>Related Questions:</Text>
                        {faq.relatedQuestions.map((relatedIndex) => (
                          faqs[relatedIndex] && (
                            <Link
                              key={relatedIndex}
                              onClick={() => setExpandedIndex(relatedIndex)}
                              color={accentColor}
                              display="block"
                              mb={1}
                              _hover={{ textDecoration: 'underline' }}
                            >
                              {faqs[relatedIndex].question}
                            </Link>
                          )
                        ))}
                      </Box>
                    </MotionAccordionPanel>
                  </AccordionItem>
                ))}
              </AnimatePresence>
            </Accordion>
          </VStack>
          <Flex justify="center" mt={12} direction="column" align="center">
            <DemoButton
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              colorScheme="blue"
              size="lg"
              rightIcon={<FaArrowRight />}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'xl',
              }}
              transition="all 0.3s"
            >
              Request a Personalized Demo
            </DemoButton>
            <Text mt={4} fontSize="sm" color={subTextColor}>
              Experience how Lexlink AI can transform your legal practice
            </Text>
          </Flex>
        </MotionBox>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Us</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input name="name" value={formData.name} onChange={handleInputChange} placeholder="Your Name" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input name="email" type="email" value={formData.email} onChange={handleInputChange} placeholder="your@email.com" />
                </FormControl>
                <FormControl>
                  <FormLabel>Company</FormLabel>
                  <Input name="company" value={formData.company} onChange={handleInputChange} placeholder="Your Company" />
                </FormControl>
                <FormControl>
                  <FormLabel>Message</FormLabel>
                  <Textarea name="message" value={formData.message} onChange={handleInputChange} placeholder="How can we help you?" />
                </FormControl>
                <Checkbox name="subscribe" isChecked={formData.subscribe} onChange={handleInputChange}>
                  Subscribe to our newsletter
                </Checkbox>
                <Text fontSize="sm" color={subTextColor}>
                  We respect your privacy and will never share your information.
                </Text>
                <HStack justify="space-between" width="100%">
                  <Text fontSize="sm" fontWeight="bold">
                    Join 500+ law firms already using Lexlink AI
                  </Text>
                  <Button type="submit" colorScheme="blue">
                    Submit
                  </Button>
                </HStack>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FAQ;
